import React from 'react';
import SocialLink from 'gatsby-theme-mate/src/components/SocialLink';
import ImageLabel from 'gatsby-theme-mate/src/components/ImageLabel';
import Hide from 'gatsby-theme-mate/src/components/Hide';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { ArtItem as ArtItemType } from '../types';
import { Card } from 'gatsby-theme-mate/src/components/Card';

type Props = ArtItemType;

const ArtItem = ({
  title,
  cw,
  media,
  received,
  artist,
}: Props) => (
  <Card p={0}>
    <Flex style={{ height: CARD_HEIGHT }}>
      <TextContainer>
        <span>
          <Title my={2} pb={1} color="text">
            {title}
          </Title>
        </span>
        <Text width={[1]} style={{ overflow: 'auto' }} color="text">
        </Text>
      </TextContainer>

      <ImageContainer>
        <ProjectImage {...media} />
        <ProjectTag>
          <Flex
            m={1}
            style={{
              float: 'right',
            }}
          >
            <Box mx={1} fontSize={4}>
            </Box>
          </Flex>
          <ImageLabel
            bg="primary"
            color="background"
            position="bottom-right"
            round
          >
            <a
              href={artist.url}
              target="__blank"
              title={artist.name}
              style={{ textDecoration: 'none' }}
            >
              {artist.name}
            </a>
          </ImageLabel>
          <Hide query="md">
            <ImageLabel bg="muted" color="primary">
              {received}
            </ImageLabel>
          </Hide>
        </ProjectTag>
      </ImageContainer>
    </Flex>
  </Card>
);

const CARD_HEIGHT = '300px';

const MEDIA_QUERY_SMALL = '@media (max-width: 400px)';

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: table;
  border-bottom: ${({ theme }) => theme.colors.primary} 5px solid;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  width: calc(100% - ${CARD_HEIGHT});
  ${MEDIA_QUERY_SMALL} {
    width: calc(100% - (${CARD_HEIGHT} / 2));
  }
`;

const ImageContainer = styled.div`
  margin: auto;
  width: ${CARD_HEIGHT};
  ${MEDIA_QUERY_SMALL} {
    width: calc(${CARD_HEIGHT} / 2);
  }
`;

const ProjectImage = styled(Image)`
  height: ${CARD_HEIGHT};
  padding: 15px;
  margin-top: 0px;
  ${MEDIA_QUERY_SMALL} {
    width: calc(${CARD_HEIGHT} / 2);
    margin-top: calc(${CARD_HEIGHT} / 4);
    padding: 10px;
  }
  object-fit: contain;
`;

const ProjectTag = styled.div`
  position: relative;
  height: ${CARD_HEIGHT};
  top: calc(
    -${CARD_HEIGHT} - 3.5px
  ); /*don't know why I have to add 3.5px here ... */
  ${MEDIA_QUERY_SMALL} {
    top: calc(-${CARD_HEIGHT} - 3.5px + (${CARD_HEIGHT} / 4));
  }
`;

export default ArtItem;
