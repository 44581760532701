import { graphql, useStaticQuery } from 'gatsby';
import { ArtItem } from '../types';

export type QueryResponse = {
    contentfulAbout: {
        artwork: {
            id: string;
            title: string;
            cw: string;
            media: {
                title: string;
                image: {
                    src: string;
                }
            };
            received: string;
            artist: {
                id: string;
                name: string;
                url: string;
            };
        }[];
    };
};

export const useArtworkQuery = (): ArtItem[] => {
  const { contentfulAbout} = useStaticQuery<QueryResponse>(graphql`
    query ArtworkQuery {
      contentfulAbout {
        artwork {
          id
          title
          cw
          media {
            title
            image: resize(width: 300, quality: 100) {
              src
            }
          }
          received(formatString: "YYYY-MM-DD")
          artist {
            id
            name
            url
          }
        }
      }
    }
  `);

  return contentfulAbout.artwork.map(({ media, ...rest }) => ({
    ...rest,
    media: {
      alt: media.title,
      src: media.image.src,
    },
  }));

}
