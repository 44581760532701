import React from 'react';
import Layout from 'gatsby-theme-mate/src/components/Layout';
import Header from 'gatsby-theme-mate/src/components/Header';
import Landing from 'gatsby-theme-mate/src/sections/Landing';
import About from 'gatsby-theme-mate/src/sections/About';
//import Projects from '../sections/Projects';
import Artwork from '../sections/Artwork';
import Writing from 'gatsby-theme-mate/src/sections/Writing';
import Footer from 'gatsby-theme-mate/src/components/Footer';

const Home = () => (
  <Layout>
    <Header />
    <Landing />
    <About />
    <Artwork />
    <Writing />
    <Footer />
  </Layout>
);

export default Home;